import { BFFProvider } from 'contexts/api/bff-context/bff-context';
import { ReturnProvider } from 'contexts/return-context/return-context';
import GlobalStyles from 'global-styles';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'styles/globals.css';
import lightTheme from 'theme';

const TermsAndConditions = React.lazy(
  () => import(/* webpackChunkName: "terms-and-conditions" */ 'pages/terms-and-conditions')
);

const Signup = React.lazy(() => import(/* webpackChunkName: "signup" */ 'pages/signup'));

const AuthenticatedApp = React.lazy(
  () => import(/* webpackChunkName: "authenticated-app" */ 'authenticated-app')
);

export const queryClient = new QueryClient();

// TODO: Call startTransition when navigation to a new screen

// TODO: Use the correct fonts!!!

const App: React.FC = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <BFFProvider>
        <ReturnProvider>
          <QueryClientProvider client={queryClient}>
            <React.Suspense fallback={null}>
              <Routes>
                {/* <Route path="/*" element={<ProtectedRoute component={AuthenticatedApp} />} /> */}
                <Route path="/*" element={<AuthenticatedApp />} />

                <Route path="terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="signup" element={<Signup />} />
                <Route path="signup/:company" element={<Signup />} />

                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </React.Suspense>

            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </ReturnProvider>
      </BFFProvider>
    </ThemeProvider>
  );
};

export default App;
